import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Carousel as AntCarousel } from "antd";
import styled from "styled-components";
import { mixin } from "utils/uiConst";

const StyledArrow = styled.button`
  z-index: 2;
  color: green !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 1px solid green !important;
  border-radius: 50% !important;
  background-color: #fff !important;
  opacity: 0.5;
  padding: 20px !important;
  font-size: 18px !important;

  &:hover {
    opacity: 1;
  }

  &.slick-prev::before,
  &.slick-next::before,
  &.slick-prev::after,
  &.slick-next::after {
    display: none;
  }

  @media (${mixin.to.md}) {
    display: none !important;
  }
`;

const CustomArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <StyledArrow {...props}>{children}</StyledArrow>
);

const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  draggable: true,
  swipeToSlide: true,
  arrows: true,
  prevArrow: (
    <CustomArrow>
      <LeftOutlined />
    </CustomArrow>
  ),
  nextArrow: (
    <CustomArrow>
      <RightOutlined />
    </CustomArrow>
  )
};

const Carousel = ({ children }) => (
  <AntCarousel {...settings}>{children}</AntCarousel>
);

export default Carousel;
